import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'

import {
  PDP_BASE_URL,
  PROFILES_BASE_URL,
  RECENTLY_VIEWED_BASE_URL,
  RECENTLY_VIEWED_REMOTE_ACCESS_URL,
  SPOONFLOWER_BASE_URL,
  SUPERGRAPH_BASE_URL,
} from '@modules/app/constants'
import { useRouter } from 'next/router'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { Link } from '../Link'
import { RecentlyViewedCarouselCompleteRemote } from '@spoonflower/recently-viewed-carousel/complete-remote'

import useWhoAmI from '../../hooks/useWhoAmI'
import BlogArticles from '../BlogArticles'
import EmptyCartHero1 from '/public/img/empty-cart-hero-1.jpg'
import EmptyCartHero2 from '/public/img/empty-cart-hero-2.jpg'
import EmptyCartHero3 from '/public/img/empty-cart-hero-3.jpg'

export const CartEmptyState = () => {
  const intl = useIntl()
  const router = useRouter()
  const { data: user, isFetched } = useWhoAmI()
  const isGuest = isFetched && user?.guest

  return (
    <>
      <Box backgroundColor="teal.50" layerStyle="hero">
        <VStack spacing="4" textAlign="center">
          <Heading as="h2" size={{ base: 'xl', lg: '3xl' }} variant="serif">
            <VStack spacing="8">
              <AiOutlineShoppingCart />
              <Text as="span">
                {intl.formatMessage({ id: 'cart.emptyState.title' })}
              </Text>
            </VStack>
          </Heading>

          <Text>{intl.formatMessage({ id: 'cart.emptyState.message' })}</Text>
        </VStack>

        <Stack
          align="center"
          direction={{ base: 'column', lg: 'row' }}
          marginBottom="10"
          marginTop="14"
          spacing={{ base: '4', lg: '8' }}
        >
          {[
            {
              id: 1,
              backgroundImage: EmptyCartHero1.src,
              href: `${SPOONFLOWER_BASE_URL}/en/living-decor`,
              text: intl.formatMessage({ id: 'cart.emptyState.hero1.text' }),
            },
            {
              id: 2,
              backgroundImage: EmptyCartHero2.src,
              href: `${SPOONFLOWER_BASE_URL}/en/wallpaper`,
              text: intl.formatMessage({ id: 'cart.emptyState.hero2.text' }),
            },
            {
              id: 3,
              backgroundImage: EmptyCartHero3.src,
              href: `${SPOONFLOWER_BASE_URL}/en/shop?on=fabric`,
              text: intl.formatMessage({ id: 'cart.emptyState.hero3.text' }),
            },
          ].map(({ id, backgroundImage, href, text }) => (
            <Flex
              key={id}
              alignItems="center"
              backgroundColor="warm-white"
              backgroundImage={backgroundImage}
              backgroundPosition="center"
              backgroundSize="cover"
              justifyContent="center"
              minHeight={{ base: '192px', lg: '204px' }}
              width="full"
            >
              <Button
                as={Link}
                color="primary-text"
                colorScheme="white"
                href={href}
                size="md"
                variant="outline"
              >
                {text}
              </Button>
            </Flex>
          ))}
        </Stack>

        <Flex justifyContent="center">
          <Button
            colorScheme="teal"
            onClick={router.back}
            size={{ base: 'md', md: 'lg' }}
            variant="solid"
            width={{ base: '100%', lg: 'auto' }}
          >
            {intl.formatMessage({ id: 'cart.emptyState.hero.continueButton' })}
          </Button>
        </Flex>
      </Box>
      <Flex layerStyle="hero" direction="column" gap={8}>
        <RecentlyViewedCarouselCompleteRemote
          url={RECENTLY_VIEWED_REMOTE_ACCESS_URL}
          domNode={document.body}
          designDataLoaderUrl={SUPERGRAPH_BASE_URL}
          pdpBaseUrl={PDP_BASE_URL}
          profilesBaseUrl={PROFILES_BASE_URL}
          recentlyViewedBaseUrl={RECENTLY_VIEWED_BASE_URL}
        />
      </Flex>
      <Box backgroundColor="gray.50" layerStyle="hero">
        <BlogArticles
          title={intl.formatMessage({ id: 'cart.emptyState.blog.title' })}
        />
      </Box>
    </>
  )
}
